<template>
    <div>
        <PreferMusicAlert v-if="preferMusicAlertStatus" @closeAlert="preferMusicAlertStatus = false" />
        <CancelUpdate v-if="cancelModalStatus" @closeCancel="cancelModalStatus = false" />
        <div class="space_pc">
            <section class="main myspace bt_pd w_940">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 style="margin-bottom:0px;">큐레이션 설정</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="myspace_content_form qration" style="padding-bottom: 0px;">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">끝으로 선호하는 노래를 선택해주세요.</h3>
                        </div>
                        <div class="pagnation" style="padding-bottom: 26px;">
                            <span></span>
                            <span style="width:100%"></span>
                        </div>
                        <div class="form_bottom_box" style="margin-bottom: 8px;">
                            <div style="width:100%;justify-content: space-between;">
                                <p style="margin-bottom:0px;">
                                    <span>{{ selectMusicIndex.length }}</span
                                    >/6
                                </p>
                                <div>
                                    <span class="reset" @click="changePreferMusciList()"
                                    ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.64167 2.95538C4.85138 1.90715 6.399 1.33115 7.99967 1.33338C11.6817 1.33338 14.6663 4.31805 14.6663 8.00005C14.6663 9.42405 14.2197 10.744 13.4597 11.8267L11.333 8.00005H13.333C13.3331 6.95446 13.0258 5.93192 12.4495 5.05955C11.8731 4.18719 11.053 3.50349 10.0911 3.09346C9.12929 2.68344 8.06815 2.56518 7.03965 2.75339C6.01114 2.9416 5.06064 3.42798 4.30634 4.15205L3.64167 2.95538ZM12.3577 13.0447C11.148 14.0929 9.60035 14.6689 7.99967 14.6667C4.31767 14.6667 1.33301 11.682 1.33301 8.00005C1.33301 6.57605 1.77967 5.25605 2.53967 4.17338L4.66634 8.00005H2.66634C2.66626 9.04563 2.97351 10.0682 3.5499 10.9405C4.12629 11.8129 4.94639 12.4966 5.90822 12.9066C6.87006 13.3167 7.9312 13.4349 8.9597 13.2467C9.98821 13.0585 10.9387 12.5721 11.693 11.848L12.3577 13.0447Z"
                                            fill="white"
                                        /></svg
                                    >새로고침
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="favorit_music_choice fade-up">
                            <ul style="padding-left: 0px;margin:0px;" v-for="(item, index) in preferMusicList" :key="index">
                                <li>
                                    <img
                                        :src="item.albumImg"
                                        style="border-radius: 50%;width:160px;height:160px;"
                                        v-if="item.albumImg != null"
                                        alt=""
                                    />
                                    <div class="click_event" :class="'click_' + index">
                                        <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0017 2.03864C17.1337 -0.77336 21.9737 -0.680027 24.991 2.34264C28.007 5.36664 28.111 10.1826 25.3057 13.324L13.999 24.6466L2.695 13.324C-0.110333 10.1826 -0.00499952 5.35864 3.00967 2.34264C6.02967 -0.676027 10.8603 -0.77736 14.0017 2.03864Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>

                                    <div
                                        class="hover_event"
                                        @click="selectMusic(item.musicId, index)"
                                        style="-webkit-user-select: none; -webkit-touch-callout: none;"
                                        @touchstart="startMuiscEvent(item.musicId, index)"
                                        @touchend="endMusicEvent"
                                    >
                                        <img
                                            class="play_btn"
                                            src="/media/img/play_btn.svg"
                                            alt=""
                                            @click.stop="musicStart(item.musicId, $event)"
                                        />
                                        <div class="rythm" @click.stop="musicEnd($event)">
                                            <div>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                        <video v-bind:id="'video' + index" width="100%" height="1" hidden playsinline></video>
                                    </div>

                                    <div
                                        class="touch_event"
                                        :class="'touch_' + index"
                                        style="-webkit-user-select: none; -webkit-touch-callout: none;"
                                        @click="selectMusic(item.musicId, index)"
                                    >
                                        <div class="rythm_2" @click.stop="endMusicPlay(index)">
                                            <div>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p style="width:160px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                                        <span v-html="item.musicNm"></span>
                                        <br /><span v-html="item.artist"></span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="form_bottom space_1">
                            <div class="form_bottom_box">
                                <div></div>
                                <div>
                                    <button id="prev_btn" style="text-align: center" @click="clickPre()">이전</button>
                                    <button
                                        id="next_btn"
                                        style="text-align: center"
                                        v-if="selectMusicIndex.length === 6 && !this.$store.state.installablePlayer"
                                        @click="updateCuration()"
                                    >
                                        다음
                                    </button>
                                    <button
                                        id="next_btn"
                                        style="text-align: center"
                                        v-else-if="selectMusicIndex.length === 6 && this.$store.state.installablePlayer"
                                        @click="updateCuration()"
                                    >
                                        완료
                                    </button>
                                    <button
                                        v-else-if="this.$store.state.installablePlayer && selectMusicIndex.length !== 6"
                                        style="text-align: center; color: #5B5B5B; background: #4D1B24"
                                        disabled
                                    >
                                        완료
                                    </button>
                                    <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 로딩바 -->
                <div
                    class="loader loader--style8"
                    style="display:flex; justify-content:center; margin-top:200px;"
                    title="7"
                    v-if="isLoading"
                >
                    <svg
                        version="1.1"
                        id="Layer_1"
                        fill="#FF2D55"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="30px"
                        viewBox="0 0 24 30"
                        style="enable-background:new 0 0 50 50;"
                        xml:space="preserve"
                    >
                        <rect x="0" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </svg>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a href="#">
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>큐레이션 설정</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width:100%"></span>
                </div>
            </div>
            <section class="main registration">
                <div class="main_box">
                    <div class="myspace_content_form" style="padding-top: 51px;">
                        <div
                            class="form_content"
                            :class="{ box_shadow: boxShadow }"
                            style="width:92%; margin-top: 11px; padding-top: 22px; position: fixed; background: #151515; z-index:5;"
                        >
                            <div class="form_text none_p">
                                <h3 style="margin-bottom:0px;">끝으로 선호하는 노래를 선택해주세요</h3>
                            </div>
                            <div class="reset">
                                <p style="margin-bottom:0px;">
                                    <span>{{ selectMusicIndex.length }}</span
                                    >/6
                                </p>
                                <div>
                                    <span style="height:30px;" @click="changePreferMusciList()"
                                    ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.64167 2.95538C4.85138 1.90715 6.399 1.33115 7.99967 1.33338C11.6817 1.33338 14.6663 4.31805 14.6663 8.00005C14.6663 9.42405 14.2197 10.744 13.4597 11.8267L11.333 8.00005H13.333C13.3331 6.95446 13.0258 5.93192 12.4495 5.05955C11.8731 4.18719 11.053 3.50349 10.0911 3.09346C9.12929 2.68344 8.06815 2.56518 7.03965 2.75339C6.01114 2.9416 5.06064 3.42798 4.30634 4.15205L3.64167 2.95538ZM12.3577 13.0447C11.148 14.0929 9.60035 14.6689 7.99967 14.6667C4.31767 14.6667 1.33301 11.682 1.33301 8.00005C1.33301 6.57605 1.77967 5.25605 2.53967 4.17338L4.66634 8.00005H2.66634C2.66626 9.04563 2.97351 10.0682 3.5499 10.9405C4.12629 11.8129 4.94639 12.4966 5.90822 12.9066C6.87006 13.3167 7.9312 13.4349 8.9597 13.2467C9.98821 13.0585 10.9387 12.5721 11.693 11.848L12.3577 13.0447Z"
                                            fill="white"
                                        /></svg
                                    >새로고침
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="favorit_music_choice" style="margin-top: 130px;">
                            <ul v-for="(item, index) in preferMusicList" :key="index">
                                <li
                                    @click="selectMusicM(item.musicId, index, $event)"
                                    :id="item.musicId"
                                    style="-webkit-touch-callout:none"
                                    v-touch:touchhold="longtapHandler(item.musicId, index)"
                                >
                                    <img
                                        :src="item.albumImg"
                                        ondragstart="return false"
                                        oncontextmenu="return false"
                                        style="-webkit-touch-callout:none"
                                        v-if="item.albumImg != null"
                                        alt=""
                                    />
                                    <div class="click_event2">
                                        <svg
                                            width="20"
                                            height="19"
                                            viewBox="0 0 20 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            v-touch:touchhold="longtapHandler2(item.musicId)"
                                        >
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <video v-bind:id="'videoM' + index" width="100%" height="1" hidden playsinline></video>
                                    <div class="m_rythm" :class="activeIndex === index ? 'active' : ''">
                                        <div
                                            class="m_rythm_circle"
                                            :class="activeIndex === index ? 'active' : ''"
                                            @click.prevent="stopVideo(index)"
                                        >
                                            <span class="m_rythm_bar"></span>
                                            <span class="m_rythm_bar"></span>
                                            <span class="m_rythm_bar"></span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p style="text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">
                                        <span v-html="item.musicNm"></span><br /><span v-html="item.artist"></span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 로딩바 -->
                <div
                    class="loader loader--style8"
                    style="display:flex; justify-content:center; margin-top:40%;"
                    title="7"
                    v-if="isLoading"
                >
                    <svg
                        version="1.1"
                        id="Layer_1"
                        fill="#FF2D55"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="30px"
                        viewBox="0 0 24 30"
                        style="enable-background:new 0 0 50 50;"
                        xml:space="preserve"
                    >
                        <rect x="0" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="8" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.15s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                        <rect x="16" y="10" width="4" height="10" fill="#FF2D55" opacity="0.2">
                            <animate
                                attributeName="opacity"
                                attributeType="XML"
                                values="0.2; 1; .2"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="height"
                                attributeType="XML"
                                values="10; 20; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="y"
                                attributeType="XML"
                                values="10; 5; 10"
                                begin="0.3s"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </svg>
                </div>
            </section>
            <div class="form_bottom">
                <div class="notice_txt choi_m" style="margin-bottom: -17px;" :class="{ active2: fadeOut }">
                    길게 눌러 음악을 미리 들어보세요.
                </div>
                <button
                    style="text-align:center;"
                    v-if="selectMusicIndex.length === 6 && !this.$store.state.installablePlayer"
                    @click="updateCuration()"
                >
                    다음
                </button>
                <button
                    style="text-align:center;"
                    v-else-if="selectMusicIndex.length === 6 && this.$store.state.installablePlayer"
                    @click="updateCuration()"
                >
                    완료
                </button>
                <button
                    class="opacity3"
                    style="text-align:center;"
                    v-else-if="selectMusicIndex.length !== 6 && this.$store.state.installablePlayer"
                    disabled
                >
                    완료
                </button>
                <button class="opacity3" style="text-align:center;" v-else disabled>다음</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import CancelUpdate from '@/components/modal/Space/CancelUpdate.vue';
import PreferMusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import $ from 'jquery';
import axios from 'axios';
import router from '@/router';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';

export default defineComponent({
  data () {
    return {
      calmList: [],
      happyList: [],
      sadList: [],
      upsetList: [],
      fantasyList: [],
      calmListAll: [],
      happyListAll: [],
      sadListAll: [],
      upsetListAll: [],
      fantasyListAll: [],
      preferMusicAlertStatus: false,
      cancelModalStatus: false,
      preferMusicList: [],
      selectMusicIndex: [],
      curationInfo: {
        mood: '',
        target: [],
        unit: [],
        domesticRate: '',
        genre: [],
        period: [],
        carPurpose: [],
        carMood: ''
      },
      headerState: 0,
      fadeOut: false,
      fadeOutCheck: true,
      isLoading: true,
      spaceInfo: {},
      playId: '',
      boxShadow: false,
      isApp: false,

      preferMusicAllList: [],
      musicStatus: false,
      activeIndex: -1,

      // 모바일 웹 미리듣기
      webTouchStatus: false
    };
  },
  components: {
    CancelUpdate,
    PreferMusicAlert
  },
  methods: {
    startMuiscEvent (url, i) {
      this.webTouchStatus = true;
      setTimeout(() => {
        if (!this.$store.state.isPlay && this.webTouchStatus) {
          const videoId = document.querySelector('video').id;
          const classList = document.getElementsByClassName('touch_event');
          for (const i in classList) {
            if (classList[i].classList !== undefined && classList[i].classList.contains('active')) {
              classList[i].classList.remove('active');
            }
          }

          document.getElementsByClassName('touch_' + i)[0].classList.add('active');
          document.getElementsByClassName('touch_' + i)[0].style.zIndex = '4';

          this.playerListener(url, videoId);
          this.playId = url;
        } else if (this.$store.state.isPlay && this.webTouchStatus) {
          this.preferMusicAlertStatus = true;
        }
      }, 1500);
    },

    endMusicEvent () {
      this.webTouchStatus = false;
    },

    endMusicPlay (i) {
      document.getElementsByClassName('touch_' + i)[0].classList.remove('active');
      document.getElementsByClassName('touch_' + i)[0].style.zIndex = '2';

      const videoId = document.querySelector('video').id;
      const video = document.getElementById(videoId);
      video.pause();
      this.playId = '';
    },

    async updateWeathreTimeInfo () {
      // 전시장 스페이스에서 날씨 시간값을 선택한 경우 날씨, 시간 값 업데이트
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const lat = 37.566826;
      const lon = 126.9786567;
      const weather = this.$attrs.selectWeatherAndTime.weather;
      const time = this.$attrs.selectWeatherAndTime.times;

      await axios.post('/api/player/getPlayerInfo ', { spaceId, lon, lat, weather, time }, { headers }).catch(err => {
        console.log(err);
      });
    },
    checkApp () {
      // 앱, 설치형 플레이어 접근 체크 - 선호감정은 앱에서는 미노출 필요
      const userAgent = navigator.userAgent.toLowerCase();
      if (
        userAgent.indexOf('aplayznativeaos') !== -1 ||
        userAgent.indexOf('aplayzaos') !== -1 ||
        userAgent.indexOf('aplayzios') !== -1
      ) { this.isApp = true; }
    },
    closeWindow () {
      window.location.href = 'aplayz://changedCuration';
      // window.open("about:blank", "_self").close();
    },
    longtapHandler2 (url) {
      return e => {
        if (!this.$store.state.isPlay) {
          var videoId = $(e.path[3]).find('video')[0].id;
          var video = document.getElementById(videoId);
          this.playerListener(url, videoId);
          video.play();
        } else {
          this.preferMusicAlertStatus = true;
        }
      };
    },
    endHandler2 () {
      return e => {
        let video;
        if (e.path === undefined) {
          video = e.target.parentElement.querySelector('video');
        } else {
          const videoId = $(e.path[3]).find('video')[0].id;
          video = document.getElementById(videoId);
        }
        video.pause();
      };
    },
    longtapHandler (musicId, idx) {
      return () => {
        if (!this.$store.state.isPlay) {
          // 기존 src 초기화
          if (document.getElementById('video').querySelector('source')) {
            document
              .getElementById('video')
              .querySelector('source')
              .setAttribute('src', '');
          }
          // 앞 video pause
          if (this.activeIndex !== -1) {
            const prevVideo = this.getPrevVideo(this.activeIndex);
            prevVideo.pause();
          }

          this.prePlayListener(`videoM${idx}`, musicId, idx);
        } else {
          this.preferMusicAlertStatus = true;
        }
      };
    },
    getVideo () {
      return document.getElementById('video');
    },
    isMobile () {
      return document.querySelector('.space_mo').style.display !== 'none';
    },
    getPrevVideo (index) {
      return document.getElementById(`videoM${index}`);
    },
    stopVideo (idx) {
      const video = this.getPrevVideo(idx);

      if (video) {
        this.activeIndex = -1;
        video.pause();
      }
    },
    createhls () {
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      return new Hls(config);
    },
    findPlayList () {
      return Array.from(document.querySelectorAll('video')).filter(item => item.id.includes('videoM') && !item.paused);
    },
    prePlayListener (videoId, url, index) {
      let hls = null;
      const preVideo = document.getElementById(videoId);
      const tg =
        window.location.host.indexOf('localhost') !== -1
          ? 'http://13.209.152.125:10084/stream/getMusic?' + url
          : '/stream/getMusic?' + url;

      // ended 이벤트 등록
      preVideo.addEventListener('ended', () => {
        this.activeIndex = -1;
      });
      // canplaythrough 이벤트 등록
      preVideo.addEventListener('canplaythrough', () => {
        const list = this.findPlayList();
        list.map(item => {
          item.pause();
        });
        if (Hls.isSupported()) {
          preVideo.play();
        }
      });
      // 모바일 일 경우 activeIndex 활성화
      if (this.isMobile()) {
        this.activeIndex = index;
      }

      // Hls.js 음악 플레이
      if (Hls.isSupported()) {
        hls = this.createhls();
        hls.loadSource(tg);
        hls.attachMedia(preVideo);
        console.log('hls : ', hls);
        // eslint-disable-next-line no-undef
      } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
        preVideo.playsInline = true;
        preVideo.autoplay = true;
        preVideo.muted = false;
        preVideo.volume = this.$store.state.volume;

        const prevsource = preVideo.querySelector('source');
        if (prevsource) {
          prevsource.remove();
        }
        const newsource = document.createElement('source');
        newsource.type = 'application/vnd.apple.mpegurl';
        newsource.src = tg;
        preVideo.appendChild(newsource);

        preVideo.play();
      }
    },
    endHandler (evt, index) {
      const video = document.getElementById(`videoM${index}`);
      const otherVideo = document.getElementById('video');
      if (video.src) {
        video.pause();
      } else {
        otherVideo.pause();
      }
    },
    musicStart (url, e) {
      if (!this.$store.state.isPlay) {
        $('.hover_event .rythm').hide();
        $('.hover_event .rythm')
          .siblings('img')
          .show();
        $('.hover_event').css('opacity', '');
        $(e.currentTarget).hide();
        $(e.currentTarget)
          .siblings('.rythm')
          .show();
        $(e.currentTarget.parentElement).css('opacity', '1');

        for (var l = 0; l < 15; l++) {
          var video = document.getElementById('video' + l);
          video.pause();
        }

        var videoId = $(e.currentTarget.parentElement).find('video')[0].id;
        video = document.getElementById(videoId);
        this.playerListener(url, videoId);
        video.play();
        this.playId = url;
      } else {
        this.preferMusicAlertStatus = true;
      }
    },
    musicEnd (e) {
      $(e.currentTarget).hide();
      $(e.currentTarget)
        .siblings('img')
        .show();
      $('.hover_event').css('opacity', '');

      var videoId = $(e.currentTarget.parentElement).find('video')[0].id;
      var video = document.getElementById(videoId);
      video.pause();
      this.playId = '';
    },
    playerListener (url, videoId) {
      this.playUrl = url;
      const _store = this.$store;
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      let hls = null;
      let video = document.getElementById(videoId);

      video.addEventListener('ended', () => {
        $('.hover_event .rythm').hide();
        $('.hover_event .rythm')
          .siblings('img')
          .show();
        $('.hover_event').css('opacity', '');

        const classList = document.getElementsByClassName('touch_event');
        for (const i in classList) {
          if (classList[i].classList !== undefined && classList[i].classList.contains('active')) {
            classList[i].classList.remove('active');
          }
        }
      });

      // autoplay for possible environment
      video.addEventListener('canplaythrough', function () {
        const promise = video.play();
        if (promise !== undefined) {
          promise
            .catch(err => {
              console.error('Auto-play was prevented: ' + err);
              // 자동재생 방지로 인한 메시지 또는 수동 재생버튼 클릭 메시지 필요
              // _store.commit("setPlay", false);
              alert('자동재생이 방지되어 있습니다. 재생버튼을 클릭해주세요.');
            })
            .then(_ => {
              video.muted = false;
            });
        }
      });

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic (url) {
        const tg =
          window.location.host.indexOf('localhost') !== -1
            ? 'http://13.209.152.125:10084/stream/getMusic?' + url
            : '/stream/getMusic?' + url;
        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          // addListener(); //add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // alert("ios");
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById('video');
          const pa = video.parentElement;

          v = document.createElement('video');
          v.id = 'video';
          v.playsInline = true;
          v.autoplay = true;
          v.muted = false;
          v.volume = _store.state.volume;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
          // addListener(); //add video player listener
          v.querySelector('source').src = tg;
        } else {
          // todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    },
    async getPreferMusicList () {
      var spaceId = localStorage.getItem('spaceId');
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getPreferMusicList', { spaceId, userId }, { headers })
        .then(res => {
          if (res.status === 200) {
            this.isLoading = false;
            var result = res.data.result;

            if (res.data.result.length > 1) {
              this.uncheck(result);
            } else {
              this.choice(result);
            }
          }
        })
        .catch(err => {
          console.log(err);
          var resultCd = err.response.data.resultCd;
          if (resultCd === 'R2001') {
            alert('선택하신 설정에 맞는 음원이 없습니다. 큐레이션 재설정 후 시도해 주세요.');
          }
        });
    },

    uncheck (result) {
      this.musicStatus = false;
      // 감정별 음악리스트
      this.calmListAll = result[0];
      this.happyListAll = result[1];
      this.sadListAll = result[2];
      this.upsetListAll = result[3];
      this.fantasyListAll = result[4];

      var array = [];
      // 감정별로 3개 가져오기
      for (var i = 0; i < result.length; i++) {
        array.push(result[i].splice(0, 3));
      }
      // 감정별로 가져온 값을 list에 넣기
      for (var j = 0; j < array.length; j++) {
        for (var k = 0; k < 3; k++) {
          this.preferMusicList.push(array[j][k]);
        }
      }
      // 배열 셔플
      /* function shuffle (array) {
        array.sort(() => Math.random() - 0.5);
      }
      shuffle(this.preferMusicList); */
      // console.log(this.preferMusicList);
      setTimeout(() => {
        this.set();
      }, 500);
    },

    choice (result) {
      this.musicStatus = true;
      this.preferMusicAllList = result[0];
      this.preferMusicList = this.preferMusicAllList.splice(0, 15);

      setTimeout(() => {
        this.set();
      }, 500);
    },

    linkMySpace () {
      this.cancelModalStatus = true;
    },
    async updateCuration () {
      // 전시장에서 날씨/시간 값 선택한 경우
      if (
        this.$attrs.selectWeatherAndTime != null &&
        this.$attrs.selectWeatherAndTime.weather != null &&
        this.$attrs.selectWeatherAndTime.times != null
      ) {
        await this.updateWeathreTimeInfo();
        this.$store.commit('setSelectWeatherAndTime', this.$attrs.selectWeatherAndTime);
      }
      const noticeTypeDetail = VueCookies.get('userId');
      const musicIdList = this.selectMusicIndex;
      const spaceId = localStorage.getItem('spaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/updateCuration', { spaceId }, { headers })
        .then(res => {
          axios
            .post('/api/profile/updatePreferMusicList', { spaceId, musicIdList }, { headers })
            .then(res1 => {
              // 큐레이션 설정값 변경 알림 보내기
              axios
                .post('/api/menu/updateCurationNotice', { spaceId, noticeTypeDetail }, { headers })
                .then(res => {
                  // 설치형 플레이어에서 호출한 경우 플레이어 호출 후 창 닫기
                  // 앱으로로 접근 시 채널선택 페이지로 이동
                  this.$store.state.installablePlayer
                    ? router.push({ name: 'InstallablePlayerUpdateCuration' })
                    : this.isApp
                      ? router.push({ name: 'MySpaceUpdateSelectChannel' })
                      : router.push({ name: 'MySpaceUpdateStore5' });
                })
                .catch(err => {
                  console.log(err);
                });
            })
            .catch(err1 => {
              console.log(err1);
            });
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickPre () {
      history.back();
    },
    set () {
      $('.rythm').hide();
      $('.favorit_music_choice ul li')
        .children('.click_event2')
        .hide();
    },
    clickReset () {
      $('.click_event').removeClass('active');
      $('.favorit_music_choice ul li')
        .children('.click_event2')
        .hide();
      this.selectMusicIndex = [];
    },
    changePreferMusciList () {
      if (!this.selectMusicIndex.includes(this.playId)) {
        for (var m = 0; m < 15; m++) {
          var video = document.getElementById('video' + m);
          video.pause();
        }
        $('.hover_event .rythm').hide();
        $('.hover_event .rythm')
          .siblings('img')
          .show();
        $('.hover_event').css('opacity', '');
      }

      if (!this.musicStatus) {
        this.changeCalm();
        setTimeout(() => {
          this.changeHappy();
        }, 100);
        setTimeout(() => {
          this.changeSad();
        }, 200);
        setTimeout(() => {
          this.changeUpset();
        }, 300);
        setTimeout(() => {
          this.changeFantasy();
        }, 400);
      } else {
        this.changePreferMusic();
      }
    },

    changePreferMusic () {
      const list = this.preferMusicList;
      for (const i in list) {
        if (this.selectMusicIndex.indexOf(list[i].musicId) === -1) {
          const item = list[i];
          list[i] = this.preferMusicAllList[i];
          this.preferMusicAllList.splice(i, 1);
          this.preferMusicAllList.push(item);
        }
      }

      this.preferMusicList = [];
      this.preferMusicList = list;
    },

    changeCalm () {
      // calm
      if (this.calmList.length === 1) {
        var calmIndex = this.calmList[0];
        var calmObj = this.preferMusicList[calmIndex];
        this.preferMusicList.splice(calmIndex, 1);

        this.calmListAll.push(this.preferMusicList[0]);
        this.calmListAll.push(this.preferMusicList[1]);

        this.preferMusicList.splice(0, 2);

        this.preferMusicList.unshift(this.calmListAll[0]);
        this.preferMusicList.unshift(this.calmListAll[1]);

        this.calmListAll.splice(0, 2);

        this.preferMusicList.splice(calmIndex, 0, calmObj);
      } else if (this.calmList.length === 2) {
        this.calmList.sort();
        var calmIndex1 = this.calmList[0];
        var calmObj1 = this.preferMusicList[calmIndex1];

        var calmIndex2 = this.calmList[1];
        var calmObj2 = this.preferMusicList[calmIndex2];

        this.preferMusicList.splice(calmIndex2, 1);
        this.preferMusicList.splice(calmIndex1, 1);

        this.calmListAll.push(this.preferMusicList[0]);

        this.preferMusicList.splice(0, 1);

        this.preferMusicList.unshift(this.calmListAll[0]);

        this.calmListAll.splice(0, 1);

        this.preferMusicList.splice(calmIndex1, 0, calmObj1);
        this.preferMusicList.splice(calmIndex2, 0, calmObj2);
      } else if (this.calmList.length === 0) {
        this.calmListAll.push(this.preferMusicList[0]);
        this.calmListAll.push(this.preferMusicList[1]);
        this.calmListAll.push(this.preferMusicList[2]);

        this.preferMusicList.splice(0, 3);

        this.preferMusicList.unshift(this.calmListAll[0]);
        this.preferMusicList.unshift(this.calmListAll[1]);
        this.preferMusicList.unshift(this.calmListAll[2]);

        this.calmListAll.splice(0, 3);
      }
    },
    changeHappy () {
      // happy
      if (this.happyList.length === 1) {
        var happyIndex = this.happyList[0];
        var happyObj = this.preferMusicList[happyIndex];
        this.preferMusicList.splice(happyIndex, 1);

        this.happyListAll.push(this.preferMusicList[3]);
        this.happyListAll.push(this.preferMusicList[4]);

        this.preferMusicList.splice(3, 2);

        this.preferMusicList.splice(3, 0, this.happyListAll[0]);
        this.preferMusicList.splice(3, 0, this.happyListAll[1]);

        this.happyListAll.splice(0, 2);

        this.preferMusicList.splice(happyIndex, 0, happyObj);
      } else if (this.happyList.length === 2) {
        this.happyList.sort();
        var happyIndex1 = this.happyList[0];
        var happyObj1 = this.preferMusicList[happyIndex1];

        var happyIndex2 = this.happyList[1];
        var happyObj2 = this.preferMusicList[happyIndex2];

        this.preferMusicList.splice(happyIndex2, 1);
        this.preferMusicList.splice(happyIndex1, 1);

        this.happyListAll.push(this.preferMusicList[3]);

        this.preferMusicList.splice(3, 1);

        this.preferMusicList.splice(3, 0, this.happyListAll[0]);

        this.happyListAll.splice(0, 1);

        this.preferMusicList.splice(happyIndex1, 0, happyObj1);
        this.preferMusicList.splice(happyIndex2, 0, happyObj2);
      } else if (this.happyList.length === 0) {
        this.happyListAll.push(this.preferMusicList[3]);
        this.happyListAll.push(this.preferMusicList[4]);
        this.happyListAll.push(this.preferMusicList[5]);

        this.preferMusicList.splice(3, 3);

        this.preferMusicList.splice(3, 0, this.happyListAll[0]);
        this.preferMusicList.splice(3, 0, this.happyListAll[1]);
        this.preferMusicList.splice(3, 0, this.happyListAll[2]);

        this.happyListAll.splice(0, 3);
      }
    },
    changeSad () {
      // sad
      if (this.sadList.length === 1) {
        var sadIndex = this.sadList[0];
        var sadObj = this.preferMusicList[sadIndex];
        this.preferMusicList.splice(sadIndex, 1);

        this.sadListAll.push(this.preferMusicList[6]);
        this.sadListAll.push(this.preferMusicList[7]);

        this.preferMusicList.splice(6, 2);

        this.preferMusicList.splice(6, 0, this.sadListAll[0]);
        this.preferMusicList.splice(6, 0, this.sadListAll[1]);

        this.sadListAll.splice(0, 2);

        this.preferMusicList.splice(sadIndex, 0, sadObj);
      } else if (this.sadList.length === 2) {
        this.sadList.sort();
        var sadIndex1 = this.sadList[0];
        var sadObj1 = this.preferMusicList[sadIndex1];

        var sadIndex2 = this.sadList[1];
        var sadObj2 = this.preferMusicList[sadIndex2];

        this.preferMusicList.splice(sadIndex2, 1);
        this.preferMusicList.splice(sadIndex1, 1);

        this.sadListAll.push(this.preferMusicList[6]);

        this.preferMusicList.splice(6, 1);

        this.preferMusicList.splice(6, 0, this.sadListAll[0]);

        this.sadListAll.splice(0, 1);

        this.preferMusicList.splice(sadIndex1, 0, sadObj1);
        this.preferMusicList.splice(sadIndex2, 0, sadObj2);
      } else if (this.sadList.length === 0) {
        this.sadListAll.push(this.preferMusicList[6]);
        this.sadListAll.push(this.preferMusicList[7]);
        this.sadListAll.push(this.preferMusicList[8]);

        this.preferMusicList.splice(6, 3);

        this.preferMusicList.splice(6, 0, this.sadListAll[0]);
        this.preferMusicList.splice(6, 0, this.sadListAll[1]);
        this.preferMusicList.splice(6, 0, this.sadListAll[2]);

        this.sadListAll.splice(0, 3);
      }
    },
    changeUpset () {
      // upset
      if (this.upsetList.length === 1) {
        var upsetIndex = this.upsetList[0];
        var upsetObj = this.preferMusicList[upsetIndex];
        this.preferMusicList.splice(upsetIndex, 1);

        this.upsetListAll.push(this.preferMusicList[9]);
        this.upsetListAll.push(this.preferMusicList[10]);

        this.preferMusicList.splice(9, 2);

        this.preferMusicList.splice(9, 0, this.upsetListAll[0]);
        this.preferMusicList.splice(9, 0, this.upsetListAll[1]);

        this.upsetListAll.splice(0, 2);

        this.preferMusicList.splice(upsetIndex, 0, upsetObj);
      } else if (this.upsetList.length === 2) {
        this.upsetList.sort(function (a, b) {
          return a - b;
        });
        var upsetIndex1 = this.upsetList[0];
        var upsetObj1 = this.preferMusicList[upsetIndex1];

        var upsetIndex2 = this.upsetList[1];
        var upsetObj2 = this.preferMusicList[upsetIndex2];

        this.preferMusicList.splice(upsetIndex2, 1);
        this.preferMusicList.splice(upsetIndex1, 1);

        this.upsetListAll.push(this.preferMusicList[9]);

        this.preferMusicList.splice(9, 1);

        this.preferMusicList.splice(9, 0, this.upsetListAll[0]);

        this.upsetListAll.splice(0, 1);

        this.preferMusicList.splice(upsetIndex1, 0, upsetObj1);
        this.preferMusicList.splice(upsetIndex2, 0, upsetObj2);
      } else if (this.upsetList.length === 0) {
        this.upsetListAll.push(this.preferMusicList[9]);
        this.upsetListAll.push(this.preferMusicList[10]);
        this.upsetListAll.push(this.preferMusicList[11]);

        this.preferMusicList.splice(9, 3);

        this.preferMusicList.splice(9, 0, this.upsetListAll[0]);
        this.preferMusicList.splice(9, 0, this.upsetListAll[1]);
        this.preferMusicList.splice(9, 0, this.upsetListAll[2]);

        this.upsetListAll.splice(0, 3);
      }
    },
    changeFantasy () {
      // fantasy
      if (this.fantasyList.length === 1) {
        var fantasyIndex = this.fantasyList[0];
        var fantasyObj = this.preferMusicList[fantasyIndex];
        this.preferMusicList.splice(fantasyIndex, 1);

        this.fantasyListAll.push(this.preferMusicList[12]);
        this.fantasyListAll.push(this.preferMusicList[13]);

        this.preferMusicList.splice(12, 2);

        this.preferMusicList.splice(12, 0, this.fantasyListAll[0]);
        this.preferMusicList.splice(12, 0, this.fantasyListAll[1]);

        this.fantasyListAll.splice(0, 2);

        this.preferMusicList.splice(fantasyIndex, 0, fantasyObj);
      } else if (this.fantasyList.length === 2) {
        this.fantasyList.sort(function (a, b) {
          return a - b;
        });
        var fantasyIndex1 = this.fantasyList[0];
        var fantasyObj1 = this.preferMusicList[fantasyIndex1];

        var fantasyIndex2 = this.fantasyList[1];
        var fantasyObj2 = this.preferMusicList[fantasyIndex2];

        this.preferMusicList.splice(fantasyIndex2, 1);
        this.preferMusicList.splice(fantasyIndex1, 1);

        this.fantasyListAll.push(this.preferMusicList[12]);

        this.preferMusicList.splice(12, 1);

        this.preferMusicList.splice(12, 0, this.fantasyListAll[0]);

        this.fantasyListAll.splice(0, 1);

        this.preferMusicList.splice(fantasyIndex1, 0, fantasyObj1);
        this.preferMusicList.splice(fantasyIndex2, 0, fantasyObj2);
      } else if (this.fantasyList.length === 0) {
        this.fantasyListAll.push(this.preferMusicList[12]);
        this.fantasyListAll.push(this.preferMusicList[13]);
        this.fantasyListAll.push(this.preferMusicList[14]);

        this.preferMusicList.splice(12, 3);

        this.preferMusicList.splice(12, 0, this.fantasyListAll[0]);
        this.preferMusicList.splice(12, 0, this.fantasyListAll[1]);
        this.preferMusicList.splice(12, 0, this.fantasyListAll[2]);

        this.fantasyListAll.splice(0, 3);
      }
    },
    divideEmotion (index) {
      switch (index) {
        case 0:
        case 1:
        case 2:
          this.calmList.includes(index)
            ? (this.calmList = this.calmList.filter(el => el !== index))
            : this.calmList.push(index);
          break;
        case 3:
        case 4:
        case 5:
          this.happyList.includes(index)
            ? (this.happyList = this.happyList.filter(el => el !== index))
            : this.happyList.push(index);
          break;
        case 6:
        case 7:
        case 8:
          this.sadList.includes(index)
            ? (this.sadList = this.sadList.filter(el => el !== index))
            : this.sadList.push(index);
          break;
        case 9:
        case 10:
        case 11:
          this.upsetList.includes(index)
            ? (this.upsetList = this.upsetList.filter(el => el !== index))
            : this.upsetList.push(index);
          break;
        default:
          this.fantasyList.includes(index)
            ? (this.fantasyList = this.fantasyList.filter(el => el !== index))
            : this.fantasyList.push(index);
          break;
      }
    },

    selectMusic (music, index) {
      const size = this.selectMusicIndex.length;
      const number = this.selectMusicIndex.indexOf(music);
      if (size < 6) {
        if (number > -1) {
          // 선택했던 음원일 경우
          this.selectMusicIndex.splice(number, 1);
          document.getElementsByClassName('click_' + index)[0].classList.remove('active');
        } else {
          // 선택한적 없는 음언일 경우
          this.selectMusicIndex.push(music);
          document.getElementsByClassName('click_' + index)[0].classList.add('active');
        }
      } else if (size === 6) {
        if (number > -1) {
          // 선택했던 음원일 경우
          this.selectMusicIndex.splice(number, 1);
          document.getElementsByClassName('click_' + index)[0].classList.remove('active');
        } else {
          alert('노래 선택은 6개까지만 가능합니다!');
        }
      } else {
        alert('노래 선택은 6개까지만 가능합니다!');
      }
    },
    selectMusicM (music, index, e) {
      var size = this.selectMusicIndex.length;
      if (size < 6) {
        if (e.target.className !== 'm_rythm_bar' && e.target.className !== 'm_rythm_circle') {
          this.selectMusicList(music);
          this.divideEmotion(index);
          $(e.currentTarget)
            .children('.click_event2')
            .toggle();
        }
      } else if (size === 6) {
        if (this.selectMusicIndex.includes(music)) {
          this.selectMusicIndex = this.selectMusicIndex.filter(el => el !== music);
          this.divideEmotion(index);
          $(e.currentTarget)
            .children('.click_event2')
            .toggle();
        } else {
          alert('노래 선택은 6개까지만 가능합니다!');
        }
      } else {
        alert('노래 선택은 6개까지만 가능합니다!');
      }
    },
    selectMusicList (music) {
      if (this.selectMusicIndex.includes(music)) {
        this.selectMusicIndex = this.selectMusicIndex.filter(el => el !== music);
      } else {
        this.selectMusicIndex.push(music);
      }
    },
    scrollEvents () {
      if (this.fadeOutCheck) {
        var headerState = this.headerState + 1;
        if (headerState > 0) {
          this.fadeOut = true;
          this.fadeOutCheck = false;
        }
      }
    },
    scrollEvents2 () {
      $(window).scroll(() => {
        var scrollValue = $(document).scrollTop();
        scrollValue === 0 ? (this.boxShadow = false) : (this.boxShadow = true);
      });
    }
  },
  async mounted () {
    this.checkApp();
    document.addEventListener('scroll', this.scrollEvents2());
    await this.getPreferMusicList();
    /* setTimeout(() => {
      this.set();
    }, 2000); */
    if (this.fadeOutCheck) {
      window.scrollTo(0, 0);
      // document.addEventListener('scroll', this.scrollEvents);
    }
  }
});
</script>
<style scoped>
.box_shadow {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}

li,
img {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hover_event {
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}

.hover_event img {
  display: none;
}

.touch_event {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: linear-gradient(360deg, rgba(255, 17, 80, 0.64) 0%, rgba(255, 17, 80, 0) 97.22%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(42, 42, 42, 0.6) 90.46%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: all 0.3s;
}

.touch_event.active {
  opacity: 1;
}

.touch_event .rythm_2 {
  position: absolute;
  top: 69%;
  left: 50%;
  transform: translateX(-52%);
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
}

.touch_event .rythm_2 > div {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.touch_event .rythm_2 > div > span {
  width: 3px;
  height: 8px;
  background: #ff1150;
  -webkit-animation: rythm 1s linear infinite;
  animation: rythm 1s linear infinite;
}

.touch_event .rythm_2 > div > span:nth-child(2) {
  height: 16px;
  margin: 0 3px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.touch_event .rythm_2 > div > span:nth-child(3) {
  height: 12px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.favorit_music_choice ul li .click_event {
  z-index: 1;
}

.favorit_music_choice ul li .hover_event {
  z-index: 3;
}

.favorit_music_choice ul li .touch_event {
  z-index: 2;
}

@media (hover: hover) and (pointer: fine) {
  .favorit_music_choice ul li:first-child:hover .hover_event {
    opacity: 1;
  }

  .favorit_music_choice ul li:first-child:hover .hover_event img {
    display: block;
  }
}

@keyframes rythm {
  0% {
    height: 100%;
  }

  50% {
    height: 50%;
  }

  100% {
    height: 100%;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
